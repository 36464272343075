import {useEffect, useState} from "react";
import {AlertSeverity, Box, BoxProps, BoxRadius, Button, ButtonStyle, Color, ContentBlock, ContentBlockRow, ContentBlockRowElementRight, InputDate, LayoutRows, Loadable, MainContentPageHeader, MainContentStyle, SearchField, SearchToolbar, SelectAutocomplete, Select, Action} from "@sirdata/ui-lib";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {buildAndDownloadCSV} from "../../common/utils/portal";
import {CHART_DATASET_DEFAULT_STYLES, GraphData} from "../../utils/chart_options";
import {ChartContainer, ChartLine} from "../../component/widget";
import {Formatter} from "../../common/utils/Formatter";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {session} from "../../api/ApiSession";
import {Site} from "../../api/model/site/Site";
import {SiteQuery} from "../../api/model/site/search/SiteQuery";
import {slugify} from "../../common/utils/string";
import {defaultStatPeriod, getStatPeriodFilter, STAT_PERIODS, StatPeriod, StatPeriodItemFilter} from "../../api/model/stat/StatPeriod";
import {pathAnalyticsHits, pathAnalyticsHitsWithId, TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {PathHelper} from "../../common/utils/PathHelper";

function AnalyticsHits() {
    const {t: textAnalytics} = useTranslation(TranslationPortalFile.ANALYTICS);
    const history = useHistory();
    const {id: configId} = useParams<{ id: string }>();

    const [sites, setSites] = useState<Site[]>([]);
    const [period, setPeriod] = useState<StatPeriod>(defaultStatPeriod.type);
    const [periodInterval, setPeriodInterval] = useState<StatPeriodItemFilter>({start: "", end: ""});
    const [dataHits, setDataHits] = useState<GraphData | undefined>(undefined);
    const [totalHits, setTotalHits] = useState(0);

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                let start = "", end = "";

                if (period === StatPeriod.CUSTOM) {
                    start = periodInterval.start;
                    end = periodInterval.end;
                } else {
                    const interval = getStatPeriodFilter(period);
                    setPeriodInterval(interval);
                    start = interval.start;
                    end = interval.end;
                }

                const dataHits = await session.restStat.getDailyHits(start, end, +configId);

                setDataHits({
                    labels: dataHits.map((it) => it.date),
                    datasets: [{
                        label: textAnalytics("label.hits"),
                        data: dataHits.map((it) => it.hits),
                        ...CHART_DATASET_DEFAULT_STYLES
                    }]
                });
                setTotalHits(dataHits.reduce((total, {hits}) => total + hits, 0));
            } catch (e) {
                UIEventManager.alert(textAnalytics("error.get_analytics"), AlertSeverity.DANGER);
            } finally {
                setLoading(false);
            }
        })();
    }, [textAnalytics, period, periodInterval, configId]);

    useEffect(() => {
        (async function () {
            try {
                const newSites = await session.restSite.getSites(new SiteQuery());
                setSites(newSites);
            } catch (e) {
            }
        })();
    }, []);

    const downloadCSVFile = () => {
        const fileTitle = textAnalytics("file.title", {name: slugify(sites.find((site) => site.id === +configId)?.name || "all")});
        const headers = [textAnalytics("file.column.date"), textAnalytics("file.column.hits")];
        let rows: string[][] = [];

        if (dataHits) {
            for (let i = 0; i < dataHits.labels.length; i++) {
                rows.push([dataHits.labels[i], dataHits.datasets[0].data[i].toString()]);
            }
        }

        buildAndDownloadCSV(fileTitle, headers, rows);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH} cssClass="analytics">
                <MainContentPageHeader
                    title={textAnalytics("title")}
                    icon={{name: "bar_chart", colorIcon: Color.CYAN}}
                />
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar>
                            <SearchField label={textAnalytics("label.site")}>
                                <SelectAutocomplete
                                    value={sites.find((site) => site.id === +configId)?.id || ""}
                                    options={[
                                        {value: "", label: `${textAnalytics("label.all")}`},
                                        ...sites.map(({id, name, domain}) => ({value: id, label: `${name} (${domain})`}))
                                    ]}
                                    onChange={(option) => history.push(!!option?.value ? PathHelper.buildPathWithId(pathAnalyticsHitsWithId, option.value as string) : pathAnalyticsHits)}
                                />
                            </SearchField>
                            <SearchField label={textAnalytics("label.period")}>
                                <Select
                                    value={period}
                                    options={[
                                        ...STAT_PERIODS.map(({type}) => ({value: type, label: textAnalytics(`option.${type}`)})),
                                        {value: StatPeriod.CUSTOM, label: textAnalytics(`option.${StatPeriod.CUSTOM}`)}
                                    ]}
                                    onChange={(option) => setPeriod(option?.value as StatPeriod)}
                                />
                            </SearchField>
                            {period === StatPeriod.CUSTOM &&
                                <>
                                    <SearchField label={textAnalytics("label.from")}>
                                        <InputDate
                                            value={periodInterval.start}
                                            onChange={(value) => setPeriodInterval((prev) => ({...prev, start: value}))}
                                            max={periodInterval.end}
                                        />
                                    </SearchField>
                                    <SearchField label={textAnalytics("label.to")}>
                                        <InputDate
                                            value={periodInterval.end}
                                            onChange={(value) => setPeriodInterval((prev) => ({...prev, end: value}))}
                                            min={periodInterval.start}
                                        />
                                    </SearchField>
                                </>
                            }
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            <ContentBlock header={{title: {label: textAnalytics("evolution_by_hits")}}} cssClass="analytics__hits">
                                <Box {...BoxProps.SECTION_BLOCK} radius={BoxRadius.MD}>
                                    <div className="analytics__hits__metric">
                                        <span className="analytics__hits__metric__header">{textAnalytics("metric.hits_total")}</span>
                                        <span className="analytics__hits__metric__value">{Formatter.formatNumber(totalHits)}</span>
                                    </div>
                                    <ChartContainer height="35rem">
                                        <ChartLine data={dataHits}/>
                                    </ChartContainer>
                                </Box>
                                <ContentBlockRow>
                                    <ContentBlockRowElementRight>
                                        <Button
                                            style={ButtonStyle.PRIMARY_MIDNIGHT}
                                            icon={Action.DOWNLOAD.icon}
                                            onClick={downloadCSVFile}
                                            children={textAnalytics("action.csv_export")}
                                        />
                                    </ContentBlockRowElementRight>
                                </ContentBlockRow>
                            </ContentBlock>
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default AnalyticsHits;
